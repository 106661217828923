import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  ReferenceField,
  Pagination
} from "react-admin";
import { Card, CardHeader, Grid } from "@material-ui/core";
import ListActionsExportAll from "../ListActionsExportAll";
import { makeStyles } from "@material-ui/core/styles";
import PageTypeToolbar from "../page-types/ButtonToolbar";

const UserAccesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const useStyles = makeStyles({
  root: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
});

export const UserAccesList = (props) => {
  const classes = useStyles();
  return (
    <Grid>
      <PageTypeToolbar
        pageTypeId={props.match.params.page_type}
        initialValue={7}
      />
      <Card className={classes.root}>
        <CardHeader title="All the users that have access to the page type" />
        <List
          {...props}
          actions={<ListActionsExportAll />}
          filter={{ page_type__id: props.match.params.page_type }}
          basePath={`/user-acces/${props.match.params.page_type}`}
          resource="user-acces"
          hasCreate={true}
          filters={<UserAccesFilter/>}
          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 500]} {...props} />}
          perPage={25}
        >
          <Datagrid rowClick="edit">
            <ReferenceField label="User" source="user" reference="users" basePath={'/user-acces'}>
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField
              label="Page Type"
              source="page_type"
              reference="page-types"
              basePath="/user-acces"
            >
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </List>
      </Card>
    </Grid>
  );
};

export default UserAccesList;
