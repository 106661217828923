import { baseDomain } from "./App";

export const fetchWithHeaders = (method, url, body) => {
  var requestParams = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      AUTHORIZATION: "Token " + localStorage.token,
    },
  };

  if (body) {
    requestParams["body"] = body;
  }

  return fetch(baseDomain + url, requestParams).then((response) => response.json());
};

export default fetchWithHeaders;
