import * as React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import {
  TextInput,
  SimpleForm,
  useNotify,
  useMutation,
  useRedirect,
  Loading,
  SaveButton,
  Toolbar,
  required,
} from "react-admin";
import { useForm } from "react-final-form";
import { ColorInput } from "react-admin-color-input";
import { baseDomain } from "../App";
import UseProfileNavigation from "./UserProfileNavigation";

export class MyProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
    };
    this.fetchUserData = this.fetchUserData.bind(this);
  }

  componentDidMount() {
    this.fetchUserData();
  }

  async fetchUserData() {
    fetch(baseDomain + "/auth/users/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        AUTHORIZATION: "Token " + localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ user: response });
      });
  }

  render() {
    return (
      <Grid>
        <UseProfileNavigation initialValue={0} />
        <Card>
          {!this.state.user && <Loading />}
          <CardHeader title="Edit your profile" />
          <SimpleForm
            save={this.props.updateUser}
            record={this.state.user}
            toolbar={this.props.toolbar}
          >
            <TextInput source="email" type="email" validate={required()} />
            <TextInput source="first_name" validate={required()} />
            <TextInput source="last_name" validate={required()} />
          </SimpleForm>
        </Card>
      </Grid>
    );
  }
}

const MyProfileToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" disabled={props.pristine} />
    </Toolbar>
  );
};

export default (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [mutate] = useMutation();

  const updateUser = ({ email, first_name, last_name, id }) =>
    mutate(
      {
        type: "update",
        resource: "users",
        payload: {
          id: id,
          data: {
            email: email,
            first_name: first_name,
            last_name: last_name,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`You just updated your profile`);
          localStorage.removeItem("user");
          redirectTo("/");
        },
        onFailure: (error) => {
          var allErrors = "";
          for (const property in error.body) {
            allErrors += `${property}:${error.body[property]},`;
          }
          notify(`Error: ${allErrors}`);
        },
      }
    );

  return (
    <MyProfilePage
      {...props}
      updateUser={updateUser}
      toolbar={<MyProfileToolbar />}
    />
  );
};
