import * as React from "react";
import {
  Toolbar,
  SaveButton,
} from "react-admin";
import DeleteButton from "./DeleteButton";

export const ToolbarDeleteConfirm = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton {...props} />
      <DeleteButton {...props} />
    </Toolbar>
  );
};

export default ToolbarDeleteConfirm;
