import * as React from "react";
import { Card, CardHeader, Grid, CardContent } from "@material-ui/core";
import {
  TextInput,
  SimpleForm,
  useNotify,
  FileField,
  FileInput,
  SelectInput,
  useMutation,
  useRedirect,
  useQuery,
  Loading,
  Error,
  SaveButton,
  Toolbar,
  Button,
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  FunctionField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ListActionsExportAll from "../ListActionsExportAll";

const PageEntityStatisticsActions = ({ history }) => {
  const redirectTo = useRedirect();
  return (
    <Toolbar>
      <ShowButton
        icon={<ArrowBack />}
        label="Back"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          history.goBack();
        }}
        onAuxClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          history.goBack();
        }}
      />
    </Toolbar>
  );
};

const useStyles = makeStyles({
  root: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
});

export const PageEntityStatistics = (props) => {
  const classes = useStyles();
  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "views",
    payload: { id: props.match.params.id },
  });

  const types = {
    PC: "Computer",
    MB: "Mobile",
    TB: "Tablet",
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <Grid>
      <PageEntityStatisticsActions {...props} />
      <Card className={classes.root}>
        <div
          style={{
            marginLeft: "1rem",
            marginTop: "1rem",
            marginRight: "1rem",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs>
              <Card>
                <CardHeader title="Total views" />
                <CardContent>{data.views}</CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card>
                <CardHeader title="PC views" />
                <CardContent>{data.pc}</CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card>
                <CardHeader title="Mobile views" />
                <CardContent>{data.mobile}</CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card>
                <CardHeader title="Tablet views" />
                <CardContent>{data.tablet}</CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        <List
          basePath={`/page-entity/${props.match.params.page_type}/${props.match.params.id}/statistics`}
          resource="acces-log"
          filter={{ page_entity__id: props.match.params.id }}
          actions={
            <ListActionsExportAll
              {...props}
              defaultFilter={{ page_entity__id: props.match.params.id }}
            />
          }
          hasCreate={false}
        >
          <Datagrid>
            <TextField source="ip" />
            <FunctionField
              label="Type"
              render={(record) => `${types[record.type]}`}
            />
            <TextField source="browser" />
            <TextField source="os" />
            <TextField source="user_agent" />
            <DateField source="date" showTime={true} />
          </Datagrid>
        </List>
      </Card>
    </Grid>
  );
};

export default PageEntityStatistics;
