import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  useQuery,
  Loading,
  Error,
  ShowButton,
  UrlField,
  RichTextField,
  EditButton,
  useRedirect,
  Filter,
  TextInput,
  BooleanField,
  BooleanInput,
  useQueryWithStore,
  Pagination,
} from "react-admin";
import { baseDomain } from "../App";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  Grid,
  Link,
  IconButton,
  Button,
} from "@material-ui/core";
import PageTypeToolbar from "../page-types/ButtonToolbar";
import ListActionsExportAll from "../ListActionsExportAll";
import MoreMenu from "./PageEntityListButtons";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import PrintIcon from "@material-ui/icons/Print";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles({
  field: {
    maxWidth: "10em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  root: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
});

const ShowButtonField = ({ source, record }) => {
  const redirectTo = useRedirect();
  return (
    <span>
      <Link href={`/${record.slug}`} title="View code">
        <IconButton aria-label="delete" color="primary" size="small">
          <VisibilityIcon />
        </IconButton>
      </Link>
      <EditButton
        basePath={`/page-entity/${record.page_type}`}
        record={record}
        label=""
        title="Edit"
      />
      <MoreMenu record={record} />
    </span>
  );
};

const PageEntityFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Slug" source="q" alwaysOn />
      {props.fields &&
        props.fields.map((field) => {
          const sourceName = `f${field.id}`;
          const labelName = `${field.configuration.name}`;
          const type = field.configuration.type;

          if (type == "text" || type == "phoneNumber") {
            return <TextInput source={sourceName} label={labelName} />;
          }

          if (type == "link") {
            return <TextInput source={sourceName} label={labelName} />;
          }

          return null;
        })}
      <BooleanInput label="Enable" source="enable" />
    </Filter>
  );
};

export const PageEntityList = (props) => {
  const classes = useStyles();
  const redirectTo = useRedirect();

  const fields = useQuery({
    type: "getList",
    resource: "field",
    payload: {
      pagination: {},
      sort: {},
      filter: { page_type__id: props.match.params.page_type },
      order: { order: "ASC" },
    },
  });

  const bulkButtons = (
    <span>
      <ShowButton
        to={`/page-types/${props.match.params.page_type}/bulk_import`}
        label="Bulk Create"
        icon={<ImportExportIcon />}
      />

      <ShowButton
        to={`/page-entity/${props.match.params.page_type}/print`}
        label="Print"
        icon={<PrintIcon />}
      />
    </span>
  );

  const page_type = useQueryWithStore({
    type: "getOne",
    resource: "page-types",
    payload: { id: props.match.params.page_type },
  });

  if (fields.loading) return <Loading />;
  if (fields.error) return <Error error={fields.error} />;
  if (!fields.data) return null;

  if (page_type.loading) return <Loading />;
  if (page_type.error) return <Error error={page_type.error} />;
  if (!page_type.data) return null;

  return (
    <Grid>
      <PageTypeToolbar
        pageTypeId={props.match.params.page_type}
        initialValue={0}
      />
      <Card className={classes.root}>
        <CardHeader title={`Pages for web site ${page_type.data.name}`} />
        <List
          basePath={`/page-entity/${props.match.params.page_type}`}
          resource="page-entity"
          filter={{ page_type__id: props.match.params.page_type }}
          hasCreate={true}
          filters={<PageEntityFilter fields={fields.data} />}
          actions={
            <ListActionsExportAll
              extraButtons={bulkButtons}
              defaultFilter={{ page_type__id: props.match.params.page_type }}
            />
          }
          pagination={
            <Pagination rowsPerPageOptions={[25, 50, 100, 500]} {...props} />
          }
          perPage={25}
        >
          <Datagrid rowClick="edit">
            <TextField source="slug" label="Page slug" />
            {fields.data.map((field) => {
              const sourceName = `fields.${field.id}.value`;
              const labelName = `${field.configuration.name}`;
              const type = field.configuration.type;

              if (
                type == "text" ||
                type == "phoneNumber" ||
                type == "singleFile" ||
                type == "identity"
              ) {
                return (
                  <TextField
                    source={sourceName}
                    label={labelName}
                    cellClassName={classes.field}
                  />
                );
              }

              if (type == "link") {
                return (
                  <UrlField
                    source={sourceName}
                    label={labelName}
                    cellClassName={classes.field}
                  />
                );
              }

              if (type == "richText") {
                return (
                  <RichTextField
                    source={sourceName}
                    label={labelName}
                    cellClassName={classes.field}
                    stripTags={true}
                  />
                );
              }
            })}
            <BooleanField source="enable" />
            <ShowButtonField />
          </Datagrid>
        </List>
      </Card>
    </Grid>
  );
};

export default PageEntityList;
