import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import indigo from "@material-ui/core/colors/indigo";
import teal from "@material-ui/core/colors/teal";
import lightGreen from "@material-ui/core/colors/lightGreen";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import { theme } from "@react-admin/ra-navigation";
import { createMuiTheme } from "@material-ui/core/styles";

const mirgTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      main: '#008000',
    },
    secondary: {
      main: '#008000',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  sidebar: {
    width: 85,
    closedWidth: 40,
  },
  overrides: {
    MuiAppBar: {
        colorSecondary: {
            color: '#ffffffff',
            backgroundColor: '#008000',
        },
    },
  }
});

export default mirgTheme;
