import * as React from "react";
import { List, Datagrid, TextField, TextInput, Filter, Pagination } from "react-admin";

const TemplatesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const TemplatesList = props => (
    <List {...props} filters={<TemplatesFilter/>} >
        <Datagrid rowClick="edit">
            <TextField source="name" />
        </Datagrid>
    </List>
);

export default TemplatesList;