export const GenericDivPluginGrapesJS = (editor) => {
  var domc = editor.DomComponents;
  const defaultType = domc.getType("default");
  const defaultModel = defaultType.model;
  const defaultView = defaultType.view;

  domc.addType("Generic container", {
    model: defaultModel.extend(
      {
        defaults: Object.assign({}, defaultModel.prototype.defaults, {
          "custom-name": "Generic container",
          tagName: "div",
          droppable: true,
          traits: defaultModel.prototype.defaults.traits,
          editable: false,
        }),
      },
      {
        isComponent(el) {
          if (el && el.classList && el.classList.contains("generic-container")) {
            return { type: "Generic container" };
          }
        },
      }
    ),
    view: defaultView,
  });

  editor.BlockManager.add("generic-container", {
    label: `<div>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" style="width:25px; padding-top:12px;padding-bottom:12px;" data-icon="window-maximize" class="svg-inline--fa fa-window-maximize fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-16 160H64v-84c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12v84z"></path></svg>
            <div>Generic container</div>
          </div>`,
    content: {
      type: "Generic container",
      style: {
        "min-height": "150px",
        "min-width": "150px",
      },
    },
    category: { label: "Layout" },
  });
};

export default GenericDivPluginGrapesJS;
