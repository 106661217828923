import * as React from "react";
import { SimpleForm, PasswordInput, useNotify, useRedirect } from "react-admin";
import { Card } from "@material-ui/core";
import { baseDomain } from "../App";

export const UserChnagePassword = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  const changePassword = ({ password, confirmPassword }) => {
    if (password === confirmPassword) {
      fetch(baseDomain + "/auth/users/reset_password_confirm/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: props.match.params.uid,
          token: props.match.params.token,
          new_password: password,
        }),
      })
        .then(() => {
          notify(`Password changed`);
          redirectTo("/users");
        })
        .catch((e) => {
          notify(`Error: "${e}"`);
        });
    } else {
      notify(`The 2 password doesn't match`);
    }
  };

  return (
    <Card>
      <SimpleForm save={changePassword}>
        <PasswordInput source="password" label="New password" />
        <PasswordInput source="confirmPassword" label="Confirm new password" />
      </SimpleForm>
    </Card>
  );
};

export default UserChnagePassword;
