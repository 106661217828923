import * as React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import {
  SimpleForm,
  useMutation,
  useRedirect,
  useNotify,
  TextInput,
  useQuery,
  Loading,
  Error,
  FileInput,
  FileField,
  BooleanInput,
  Toolbar,
  ShowButton,
} from "react-admin";
import GenerateInput from "../page-fields/GenerateInput";
import {convertFileToBase64} from "../addUploadFeature";
import { PageTypeToolbar } from "../page-types/ButtonToolbar";
import ArrowBack from "@material-ui/icons/ArrowBack";
import BackButtonToolbar from "../BackButtonToolbar";

export const PageEntityCreate = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [mutate] = useMutation();

  const page_type = props.match.params.page_type;

  const { data, loading, error } = useQuery({
    type: "getList",
    resource: "field",
    payload: {
      pagination: {},
      sort: {},
      filter: { page_type__id: page_type },
      order: { order: "ASC" },
    },
  });

  const processBulkImport = async ({bulk_file}) => {
    const bulk_file_base64 = await convertFileToBase64(bulk_file);
    return mutate(
      {
        type: "create",
        resource: "page-types/bulk_import",
        payload: {
          data: {
            page_type: page_type,
            bulk_file: bulk_file_base64,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`${data.created} new page entiries have been added`);
          redirectTo(`/page-entity/${page_type}`);
        },
        onFailure: (error) => {
          const msg = error.body.non_field_errors[0] || 'Failed to create entries';
          notify(`Error: "${msg}`);
        },
      }
    );
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  const formatFileInput = (value) => {
    if (!value || typeof value === "string") {
      return { src: value };
    } else {
      return value;
    }
  };

  return (
    <Grid>
      <BackButtonToolbar {...props} />
      <Card>
        <CardHeader title="Bulk import entities" />
        <SimpleForm save={processBulkImport}>
          <FileInput
            source="bulk_file"
            format={formatFileInput}
            label=".xlsx file"
          >
            <FileField source="src" title="Logo" target="_blank" />
          </FileInput>
        </SimpleForm>
      </Card>
    </Grid>
  );
};

export default PageEntityCreate;
