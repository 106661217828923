import { AuthProvider, fetchUtils } from "ra-core";
import {baseDomain} from "./App";

export function tokenAuthProvider(options) {
  const opts = {
    obtainAuthTokenUrl: "/api-token-auth/",
    ...options,
  };
  return {
    login: async ({ username, password }) => {
      const request = new Request(opts.obtainAuthTokenUrl, {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      });
      const response = await fetch(request);
      if (response.ok) {
        localStorage.setItem("token", (await response.json()).auth_token);
        return;
      }
      if (response.headers.get("content-type") !== "application/json") {
        throw new Error(response.statusText);
      }

      const json = await response.json();
      const error = json.non_field_errors;
      throw new Error(error || response.statusText);
    },
    logout: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return Promise.resolve();
    },
    checkAuth: () =>
      localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
    checkError: (error) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        return Promise.reject();
      }
      return Promise.resolve();
    },
    getPermissions: () => {
      return fetch(baseDomain + "/auth/users/me", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          AUTHORIZATION: "Token " + localStorage.token,
        },
      })
        .then((response) => response.json())
        .then((response) => {return response ? Promise.resolve(response.is_org_admin) : Promise.reject();});
    },
    getIdentity: () => {
      try {
          return fetch(baseDomain + "/auth/users/me", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              AUTHORIZATION: "Token " + localStorage.token,
            },
          })
          .then((response) => response.json())
          .then((response) => {
            const { id, email,username, avatar } = response;
            window.isChatwootLoaded.then(value=>{
              window.$chatwoot.setUser(id, {
                email: email,
                name: username,
              });
            });
            return Promise.resolve({ id, fullName:email, avatar });
          });
      } catch (error) {
          return Promise.reject(error);
      }
  }
  };
}

export function createOptionsFromToken() {
  const token = localStorage.getItem("token");
  if (!token) {
    return {};
  }
  return {
    user: {
      authenticated: true,
      token: "Token " + token,
    },
  };
}

export function fetchJsonWithAuthToken(url: string, options: object) {
  console.log(options);
  return fetchUtils.fetchJson(
    url,
    Object.assign(createOptionsFromToken(), options)
  );
}

export default tokenAuthProvider;
