import * as React from "react";
import { useField } from "react-final-form";
import FontIconPicker from "@fonticonpicker/react-fonticonpicker";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css";
import { fontAwesome } from "./Incons";
import "@fortawesome/fontawesome-free/css/all.css";

export const InconInput = ({ name, label, initialValue }) => {
  const [icon, setIcon] = React.useState(initialValue ? initialValue : "");

  const {
    input: { onChange },
  } = useField(name, { initialValue: initialValue });
  //TODO: Fix search
  return (
    <FontIconPicker
      icons={fontAwesome}
      value={icon}
      onChange={(val) => {
        onChange(val);
        setIcon(val);
      }}
      isMulti={false}
      id="name"
      search={null}
    />
  );
};

export default InconInput;
