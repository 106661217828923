import * as React from "react";
import { Card, CardHeader } from "@material-ui/core";
import {
  SimpleForm,
  NumberInput,
  BooleanInput,
  SelectInput,
  useMutation,
  useRedirect,
  useNotify,
  TextInput,
  FileInput,
  FileField,
  SaveButton,
  Toolbar,
} from "react-admin";
import GenerateInput from "./GenerateInput";
import InconInput from "./IconInput";
import { convertFileToBase64 } from "../addUploadFeature";
import RichTextInput from "ra-input-rich-text";
import PageTypeFile from "../page-types/PageTypeFile";

const formatFileInput = (value) => {
  if (!value || typeof value === "string") {
    return { src: value };
  } else {
    return value;
  }
};

const validatePageField = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = ["The name is required"];
  }
  if (!values.type) {
    errors.type = ["The type is required"];
  }
  if (
    values.type &&
    values.type == "fileManager" &&
    (!values.value || values.value == "")
  ) {
    errors.value = ["No path will be shared, a folder needs to be selected"];
  }
  if (values.type && values.type == "fileManager" && !values.global) {
    errors.global = ["A PageField with type File Manager can be only global"];
  }
  if (values.type && values.type == "identity" && values.global) {
    errors.global = ["A PageField with type Identity can't be global"];
  }
  return errors;
};

const PageFieldCreateToolbarDisplayNone = (props) => {
  return (
    <Toolbar {...props} style={{ display: "none" }}>
      <SaveButton label="Save" disabled={props.pristine} innerRef={props.saveButtonRef}/>
    </Toolbar>
  );
};

const PageFieldCreateToolbarDisplay = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};

const PageFieldCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="Save"
        handleSubmitWithRedirect={(e) => {
          props.saveButtonRef.current.click();
        }}
      />
    </Toolbar>
  );
};

export const PageFieldCreate = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [mutate] = useMutation();
  const [fileManagerFieldState, changeFileManagerFieldState] =
    React.useState("");
  const firstInputRef = React.useRef();
  const [showFileManager, changeShowFileManger] = React.useState(false);
  const [grapesEditor, changeGrapesEditor] = React.useState();
  const saveButtonRef = React.useRef();

  const page_type = props.match.params.page_type;

  const createPageField = async ({
    type,
    order,
    global,
    value,
    name,
    icon,
    emailUpdatebal,
    sftpUpdatable,
    sftpSetting,
    icon_img,
    body,
  }) => {
    if (global && value && type == "singleFile") {
      value = [value];
    }

    const icon_img_file = icon_img && (await convertFileToBase64(icon_img));

    var editorProps = {};
    if (type == "richText" && global) {
      editorProps["value"] = grapesEditor.getHtml();
      editorProps["css"] = grapesEditor.getCss({ avoidProtected: true });
    }

    return mutate(
      {
        type: "create",
        resource: "page-field",
        payload: {
          data: {
            order: order,
            page_type: page_type,
            configuration: {
              type: type,
              global: global,
              value:
                type == "fileManager"
                  ? value.path
                    ? value.path
                    : value
                  : value,
              name: name,
              icon: icon,
              body: body,
              emailUpdatebal: emailUpdatebal,
              sftpUpdatable: sftpUpdatable,
              sftpSetting: sftpSetting,
              icon_img: icon_img_file,
              ...editorProps,
            },
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`You just created the page filed "${data.id}"`);
          redirectTo(`/page-field/${page_type}`);
        },
        onFailure: (error) => {
          var allErrors = "";
          for (const property in error.body) {
            allErrors += `${property}:${error.body[property]},`;
          }
          notify(`Error: ${allErrors}`);
        },
      }
    );
  };

  return (
    <Card>
      <CardHeader title="Create a new page field" />
      <SimpleForm
        save={createPageField}
        validate={validatePageField}
        basePath={`/page-field/${page_type}`}
        resource={"page-field"}
        submitOnEnter={false}
        action="javascript: void(0)"
        {...(showFileManager
          ? { toolbar: <PageFieldCreateToolbarDisplayNone saveButtonRef={saveButtonRef} /> }
          : { toolbar: <PageFieldCreateToolbarDisplay /> })}
      >
        <InconInput name="icon" />
        <FileInput
          source="icon_img"
          format={formatFileInput}
          label="Icon"
          accept="image/*"
          helperText="Each field can have an icon, you can choose one from the first field if you don't like the default ones you can upload an image"
        >
          <FileField source="src" title="Logo" target="_blank" />
        </FileInput>
        <TextInput
          source="name"
          inputRef={firstInputRef}
          helperText="This text will be shown on the button on the QR page"
        />
        <SelectInput
          source="type"
          choices={[
            { id: "text", name: "Text" },
            { id: "link", name: "Link" },
            { id: "phoneNumber", name: "Phone number" },
            { id: "richText", name: "Rich text" },
            { id: "singleFile", name: "Single file" },
            { id: "fileManager", name: "File Manager" },
            { id: "identity", name: "Identity" },
          ]}
          helperText="This field defines what type of content will be shown on the QR page. Single file: you can link a unique file to and individual. Rich text: This will let you customize the way the text locks"
        />
        <NumberInput
          source="order"
          defaultValue={0}
          helperText="The order is a number that sets the order in which the fields will be shown on the QR page. If the field is empty on create the number will be set to be the highest"
        />
        <RichTextInput
          source="body"
          label="Body"
          helperText="In some cases, the name is not enough and the body field lets you customize the text that is deployed on the button on the QR page"
        />
        <BooleanInput
          source="global"
          defaultValue={true}
          helperText="If this switch is on the value for this field is the same for all the QRs, if it is off you need to set the value for each QR"
        />
        <GenerateInput
          page_type={page_type}
          fileManagerFieldState={fileManagerFieldState}
          changeShowFileManger={changeShowFileManger}
          changeGrapesEditor={changeGrapesEditor}
        />
      </SimpleForm>
      {showFileManager && (
        <PageTypeFile
          page_type={page_type}
          onSelectionChanged={changeFileManagerFieldState}
          hasToolBar={false}
        />
      )}
      {showFileManager && (
        <PageFieldCreateToolbar
          {...props}
          firstTestRef={firstInputRef}
          saveButtonRef={saveButtonRef}
        />
      )}
    </Card>
  );
};

export default PageFieldCreate;
