import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ListIcon from "@material-ui/icons/List";
import { Toolbar, useRedirect } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import StyleIcon from "@material-ui/icons/Style";
import DnsIcon from "@material-ui/icons/Dns";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "transparent"
  },
});

export const UseProfileNavigation = ({ initialValue }) => {
  const [value, setValue] = React.useState(initialValue);
  const redirectTo = useRedirect();
  const classes = useStyles();

  const links = {
    0: `/profile`,
    1: `/profile/change-password`,
  };

  const handleChange = (event, newValue) => {
    redirectTo(links[newValue]);
  };

  return (
    <Toolbar>
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label="Profile" icon={<AccountCircleIcon />} />
      <BottomNavigationAction label="Change password" icon={<LockIcon />} />
    </BottomNavigation>
    </Toolbar>
  );
};

export default UseProfileNavigation;
