import * as React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  useQuery,
  Loading,
  Error,
  useMutation,
  useNotify,
  useRedirect,
  required,
} from "react-admin";
import { Card, CardHeader } from "@material-ui/core";
import ToolbarDeleteConfirm from "../ToolbarDeleteConfirm";

export const UserEdit = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "users",
    payload: { id: props.id },
  });

  const [mutate] = useMutation();

  const updateUser = ({ username, email, first_name, last_name }) =>
    mutate(
      {
        type: "update",
        resource: "users",
        payload: {
          id: props.id,
          data: {
            username: username,
            email: email,
            first_name: first_name,
            last_name: last_name,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`You just updated the user "${data.username}"`);
          redirectTo("/users");
        },
        onFailure: (error) => {
          var allErrors = "";
          for (const property in error.body) {
            allErrors += `${property}:${error.body[property]},`;
          }
          notify(`Error: ${allErrors}`);
        },
      }
    );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <Edit {...props} title={`User ${data.username}`}>
      <Card>
        <CardHeader title={`Edit user ${data.username}`} />
        <SimpleForm
          save={updateUser}
          record={data}
          // toolbar={<ToolbarDeleteConfirm />}
        >
          <TextInput source="username" validate={required()} />
          <TextInput source="email" />
          <TextInput source="first_name" validate={required()} />
          <TextInput source="last_name" />
        </SimpleForm>
      </Card>
    </Edit>
  );
};

export default UserEdit;
