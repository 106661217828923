import * as React from "react";
import {
  Button,
  useRedirect,
  useMutation,
  useNotify,
  ShowButton,
} from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";

export const DeleteButton = (props) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const [mutate] = useMutation();

  return (
    <ShowButton
      label="Delete"
      icon={<DeleteIcon />}
      className="MuiButton-root MuiButton-text RaButton-button-43 ra-delete-button RaDeleteWithUndoButton-deleteButton-137 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
      onClick={async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const pass = window.confirm("Are you sure you want to delete?");
        if (pass) {
          await mutate({
            type: "delete",
            resource: props.resource,
            payload: { id: props.record.id, previousData: props.record },
          });
          redirectTo(props.basePath);
        }
      }}
      onAuxClick={async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const pass = window.confirm("Are you sure you want to delete?");
        if (pass) {
          await mutate({
            type: "delete",
            resource: props.resource,
            payload: { id: props.record.id, previousData: props.record },
          });

          redirectTo(props.basePath);
        }
      }}
    />
  );
};

export default DeleteButton;
