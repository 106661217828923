import * as React from "react";
import { Toolbar, ShowButton } from "react-admin";
import ArrowBack from "@material-ui/icons/ArrowBack";

export const BackButtonToolbar = ({ history }) => {
  return (
    <Toolbar>
      <ShowButton
        icon={<ArrowBack />}
        label="Back"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          history.goBack();
        }}
        onAuxClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          history.goBack();
        }}
      />
    </Toolbar>
  );
};

export default BackButtonToolbar;
