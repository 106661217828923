import * as React from "react";
import { Card, CardHeader, Grid, CardContent } from "@material-ui/core";
import {
  List,
  Datagrid,
  TextField,
  Pagination
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ListActionsExportAll from "../ListActionsExportAll";
import { baseDomain } from "../App";

const useStyles = makeStyles({
  root: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
});


export class OrganizationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_qr: 0,
      org_name: "",
      max_qrs: 0
    };
  }

  componentDidMount() {
    const fetchStatistics = async () => {
      const response = await fetch(baseDomain + "/organizations/total_qr", {
        headers: {
          "Content-Type": "application/json",
          AUTHORIZATION: "Token " + localStorage.token,
        },
      });
      const { total_qr, org_name, max_qr } = await response.json();
      this.setState({
        total_qr: total_qr,
        org_name: org_name,
        max_qrs: max_qr
      });
    };
    fetchStatistics();
  }

  render() {
    return (
      <Grid>
        <Card className={this.props.classes.root}>
          <CardHeader title={`Organization ${this.state.org_name}`} />
          <div
            style={{
              marginLeft: "1rem",
              marginTop: "1rem",
              marginRight: "1rem",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card>
                  <CardHeader title="Total codes" />
                  <CardContent> {this.state.total_qr}</CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardHeader title="Max codes" />
                  <CardContent> {this.state.max_qrs}</CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
          <List
            basePath={`/organization`}
            resource="organizations/statistics"
            actions={<ListActionsExportAll {...this.props} />}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            bulkActionButtons={false}
            title="Organization"
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 500 ]} {...this.props} />}
            perPage={25}
          >
            <Datagrid>
              <TextField source="name" />
              <TextField source="qr_count" label="Codes" />
            </Datagrid>
          </List>
        </Card>
      </Grid>
    );
  }
}

export default (props) => {
  const classes = useStyles();
  return <OrganizationPage classes={classes} {...props} />;
};