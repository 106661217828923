import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  FunctionField,
  Filter,
  TextInput,
  BooleanInput,
  SelectInput,
  Loading,
  Error,
  useQueryWithStore,
  Pagination
} from "react-admin";
import { Card, CardHeader, Grid } from "@material-ui/core";
import PageTypeToolbar from "../page-types/ButtonToolbar";
import { makeStyles } from "@material-ui/core/styles";
import ListActionsExportAll from "../ListActionsExportAll";

const useStyles = makeStyles({
  root: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
});

const PageFieldFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn />
    {/* <TextInput label="Type" source="configuration__type" /> */}
    <BooleanInput label="Global" source="configuration__global" />
    <SelectInput
          label="Type"
          source="configuration__type"
          choices={[
            { id: "text", name: "Text" },
            { id: "link", name: "Link" },
            { id: "phoneNumber", name: "Phone number" },
            { id: "richText", name: "Rich text" },
            { id: "singleFile", name: "Single file" },
            { id: "identity", name: "Identity" },
          ]}
        />
  </Filter>
);

export const PageFieldList = (props) => {
  const types = {
    text: "Text",
    link: "Link",
    phoneNumber: "Phone number",
    richText: "Rich text",
    singleFile: "Single file",
    fileManager: "FileManager",
    identity: "Identity"
  };

  const classes = useStyles();

  const page_type = useQueryWithStore({
    type: "getOne",
    resource: "page-types",
    payload:{ id: props.match.params.page_type},
  });

  if (page_type.loading) return <Loading />;
  if (page_type.error) return <Error error={page_type.error} />;
  if (!page_type.data) return null;

  return (
    <Grid>
      <PageTypeToolbar pageTypeId={props.match.params.page_type} initialValue={5} />
      <Card className={classes.root}>
        <CardHeader title={`All the fields of web site ${page_type.data.name}`} />
        <List
          basePath={`/page-field/${props.match.params.page_type}`}
          resource="page-field"
          filter={{ page_type__id: props.match.params.page_type }}
          sort={{ field: "order", order: "ASC" }}
          hasCreate={true}
          filters={<PageFieldFilter />}
          actions={<ListActionsExportAll defaultFilter={{ page_type__id: props.match.params.page_type }} />}
          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 500]} {...props} />}
          perPage={25}
        >
          <Datagrid rowClick="edit">
            <TextField source="configuration.name" label="Name" />
            <FunctionField
              label="Type"
              render={(record) => `${types[record.configuration.type]}`}
            />
            <BooleanField source="configuration.global" label="Global" />
            <NumberField source="order" />
          </Datagrid>
        </List>
      </Card>
    </Grid>
  );
};

export default PageFieldList;
