import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ListIcon from "@material-ui/icons/List";
import { Toolbar, useRedirect, usePermissions } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import StyleIcon from "@material-ui/icons/Style";
import DnsIcon from "@material-ui/icons/Dns";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "transparent"
  },
  toolbarButtons: {
    marginLeft: "auto",
    marginRight: -12,
  },
});

export const PageTypeToolbar = ({ initialValue, pageTypeId}) => {
  const [value, setValue] = React.useState(initialValue);
  const redirectTo = useRedirect();
  const classes = useStyles();
  const {loading, permissions} = usePermissions();

  const links = {
    0: `/page-entity/${pageTypeId}`,
    3: `/page-types/${pageTypeId}`,
    4: `/page-types/${pageTypeId}/style`,
    5: `/page-field/${pageTypeId}`,
    1: `/page-type/${pageTypeId}/statistics`,
    2: `/page-types/${pageTypeId}/file`,
    6: `/page-types/${pageTypeId}/template`,
    7: `/user-acces/${pageTypeId}`,
  };

  const handleChange = (event, newValue) => {
    redirectTo(links[newValue]);
  };

  return (
    <Toolbar>
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label="Pages" icon={<ListIcon />} title="All the pages of this web site, can add, edit and delete"/>
      <BottomNavigationAction label="Statistics" icon={<EqualizerIcon />}  title="Statistic for all the pages of this web site"/>
      <BottomNavigationAction label="File Manager" icon={<FolderIcon />} title="The file manager of this web site"/>
      <BottomNavigationAction label="Configuration" icon={<SettingsIcon />} className={classes.toolbarButtons} title="Make settings for the web site" />
      <BottomNavigationAction label="Style" icon={<StyleIcon />} title="Make style settings for the web site, these will be visible on all pages" />
      <BottomNavigationAction label="Fields" icon={<DnsIcon />} title="All the fields of the pages, can add, delete and edit"/>
      <BottomNavigationAction label="Template" icon={<DescriptionIcon />} title="All the fields of the pages, can add, delete and edit"/>
      <BottomNavigationAction label="Users" icon={<PeopleAltIcon />} title="All the users that can modify this web site, can add and delete" disabled={permissions!==true}/>
    </BottomNavigation>
    </Toolbar>
  );
};

export default PageTypeToolbar;
