import * as React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import {
  SimpleForm,
  useMutation,
  useRedirect,
  useNotify,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import BackButtonToolbar from "../BackButtonToolbar";

export const UserAccesCreate = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [mutate] = useMutation();

  const page_type = props.match.params.page_type;

  const createUserAcces = ({ user }) => {
    return mutate(
      {
        type: "create",
        resource: "user-acces",
        payload: {
          data: {
            page_type: page_type,
            user: user,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(
            `You just give the permision to acces page type to user ${user}`
          );
          redirectTo(`/user-acces/${page_type}`);
        },
        onFailure: (error) => {
          var allErrors = "";
          for(const property in error.body){
            allErrors+=error.body[property];
          }
          notify(`Error: "${allErrors}"`);
        },
      }
    );
  };

  return (
    <Grid>
      <BackButtonToolbar {...props} />
      <Card>
        <CardHeader title="Give permision to user" />
        <SimpleForm save={createUserAcces}>
          <ReferenceInput
            source="user"
            reference="users"
            label="Select the user"
            perPage={100}
          >
            <SelectInput optionText="username" />
          </ReferenceInput>
        </SimpleForm>
      </Card>
    </Grid>
  );
};

export default UserAccesCreate;
