import * as React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  useRefresh,
  useRedirect,
  SelectInput,
  required
} from "react-admin";
import { CardHeader } from "@material-ui/core";

export const PageTypeCreate = (props) => {
  const refresh = useRefresh();
  const redirectTo = useRedirect();
  return (
    <Create
      {...props}
      onSuccess={(data) => {
        redirectTo("/page-types");
        refresh();
      }}
    >
      <SimpleForm>
        <CardHeader title="Create a new web site" />
        <TextInput source="name" helperText="The name of the web site" validate={required()} />
        <TextInput source="description" helperText="A short description of the web site" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default PageTypeCreate;
