import * as React from "react";
import { Card, CardHeader } from "@material-ui/core";
import {
  SimpleForm,
  useMutation,
  useRedirect,
  useNotify,
  TextInput,
  useQuery,
  Loading,
  Error,
  BooleanInput,
  Toolbar,
  SaveButton,
} from "react-admin";
import GenerateInput from "../page-fields/GenerateInput";
import { convertFileToBase64 } from "../addUploadFeature";

const PageEntityToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={false}/>
    </Toolbar>
  );
};

export const PageEntityCreate = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [mutate] = useMutation();

  const page_type = props.match.params.page_type;

  const { data, loading, error } = useQuery({
    type: "getList",
    resource: "field",
    payload: {
      pagination: {},
      sort: {},
      filter: { page_type__id: page_type },
      order: { order: "ASC" },
    },
  });

  const createPageEntity = async (args) => {
    const fields_value = {};
    for (const key of Object.keys(args)) {
      const newKey = key.substr(1);
      if (args[key].rawFile instanceof File) {
        const path = await convertFileToBase64(args[key]);
        args[key] = { value: path, fileName: args[key].File };
        fields_value[newKey] = args[key];
        continue;
      }
      if (key !== "slug" && key !== "enable") {
        fields_value[newKey] = { value: args[key] };
      }
    }
    return mutate(
      {
        type: "create",
        resource: "page-entity",
        payload: {
          data: {
            page_type: page_type,
            slug: args["slug"],
            fields: { ...fields_value },
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`You just created the page entity "${data.id}"`);
          redirectTo(`/page-entity/${page_type}`);
        },
        onFailure: (error) => {
          var allErrors = "";
          for (const property in error.body) {
            allErrors += `${property}:${error.body[property]},`;
          }
          notify(`Error: ${allErrors}`);
        },
      }
    );
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <Card>
      <CardHeader title="Create a new page" />
      <SimpleForm save={createPageEntity} toolbar={<PageEntityToolbar />}>
        <TextInput
          source="slug"
          helperText="This is a unique identifier of the QR"
        />
        {data.map((field) => (
          <GenerateInput source={field} />
        ))}
        <BooleanInput
          source="enable"
          initialValue={true}
          helperText="If this enables the QR is visible, if it isn't the QR page can't be accessed"
        />
      </SimpleForm>
    </Card>
  );
};

export default PageEntityCreate;
