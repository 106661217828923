import * as React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import {
  getResources,
  Loading,
  Error,
  Layout,
  useQueryWithStore,
  usePermissions,
} from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import GroupIcon from "@material-ui/icons/Group";
import AppBar from "./AppBar";
import {
  AppLocationContext,
  Menu,
  MenuItem,
  MenuItemCategory,
  MultiLevelMenu,
  theme,
} from "@react-admin/ra-navigation";
import { CardContent, Typography } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LanguageIcon from "@material-ui/icons/Language";
import DescriptionIcon from "@material-ui/icons/Description";

const CustomMenu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const { data, loading, error } = useQueryWithStore({
    type: "getList",
    resource: "page-types",
    payload: { pagination: {perPage: 25}, sort: { field: 'name', order: 'ASC'}, filter: {} },
  });
  const { permissions } = usePermissions();

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <MultiLevelMenu variant="categories">
      <MenuItemCategory
        name="PageTypes"
        to={"/page-types?filter={}"}
        label="Web sites"
        icon={<LanguageIcon />}
      >
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Web sites
          </Typography>
          <Menu>
            <MenuItem
              name="allWebSites"
              to={`/page-types/`}
              label="All Web sites"
              icon={<DefaultIcon />}
            />
            {data.map((pageType) => (
              <MenuItem
                name={pageType.name}
                to={`/page-entity/${pageType.id}`}
                label={pageType.name}
              />
            ))}
          </Menu>
        </CardContent>
      </MenuItemCategory>
      {permissions && (
        <div>
          <MenuItemCategory
            name="templates"
            to="/templates"
            label="Templates"
            icon={<DescriptionIcon />}
          />
          <MenuItemCategory
            name="users"
            to="/users"
            label="Users"
            icon={<GroupIcon />}
          />
          <MenuItemCategory
            name="organization"
            to="/organization"
            label="Organization"
            icon={<ApartmentIcon />}
          />
        </div>
      )}
      {isXSmall}
    </MultiLevelMenu>
  );
};

const CustomLayout = (props) => (
  <AppLocationContext>
    <Layout {...props} menu={CustomMenu} appBar={AppBar} />
  </AppLocationContext>
);

export default CustomLayout;
