import * as React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import {
  TextInput,
  SimpleForm,
  useNotify,
  FileField,
  FileInput,
  SelectInput,
  useMutation,
  useRedirect,
  useQuery,
  Loading,
  Error,
  SaveButton,
  Toolbar,
  Button,
  useAuthProvider
} from "react-admin";
import { useForm } from "react-final-form";
import { ColorInput } from "react-admin-color-input";
import { baseDomain } from "../App";
import { PageTypeToolbar } from "./ButtonToolbar";

const StyleToolbar = (props) => {
  const form = useForm();
  const formdata = form.getState().values;

  return (
    <Toolbar {...props}>
      <SaveButton label="Save" disabled={props.pristine} />
    </Toolbar>
  );
};

export const PageTypeStyle = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const authProvider = useAuthProvider();

  const id = props.match.params.page_id;

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "page-types",
    payload: { id: id },
  });

  const [mutate] = useMutation();

  const updateStyle = ({
    id,
    logo,
    template_name,
    font_color,
    background_color,
    card_color,
    card_hover_color,
    card_background_color,
    card_background_hover_color,
  }) => {
    mutate(
      {
        type: "update",
        resource: "page-types",
        payload: {
          id: id,
          data: {
            logo: logo,
            template_name: template_name,
            background_color: background_color,
            font_color: font_color,
            card_color: card_color,
            card_hover_color: card_hover_color,
            card_background_color: card_background_color,
            card_background_hover_color: card_background_hover_color,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`You just updated the style for "${data.name}"`);
          redirectTo("/page-types");
        },
        onFailure: (error) => {
          notify(`Error: "${error}"`);
        },
      }
    );

  }

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  const formatFileInput = (value) => {
    if (!value || typeof value === "string") {
      return { src: value };
    } else {
      return value;
    }
  };

  return (
    <Grid>
      <PageTypeToolbar pageTypeId={data.id} initialValue={4} />
      <Card>
        <CardHeader title={`Change the style of the web site ${data.name}`} />
        <SimpleForm save={updateStyle} toolbar={<StyleToolbar />} record={data}>
          <TextInput source="id" disabled />
          <p>The following setting will be applied on the qr page theme</p>
          <ColorInput source="background_color" picker="Sketch" parse={(value)=>(value)} />
          <ColorInput source="font_color" picker="Sketch" parse={(value)=>(value)}/>
          <ColorInput source="card_color" picker="Sketch" parse={(value)=>(value)}/>
          <ColorInput source="card_hover_color" picker="Sketch" parse={(value)=>(value)}/>
          <ColorInput source="card_background_color" picker="Sketch" parse={(value)=>(value)}/>
          <ColorInput source="card_background_hover_color" picker="Sketch" parse={(value)=>(value)}/>
          <SelectInput
            source="template_name"
            choices={[
              { id: "lexauto.html", name: "lexauto.html" },
              { id: "tp.html", name: "tp.html" },
              { id: "days.html", name: "days.html" },
            ]}
            helperText="The template describes how each QR page will look"
          />
          <FileInput
            source="logo"
            format={formatFileInput}
            label="Logo"
            accept="image/*"
          >
            <FileField source="src" title="Logo" target="_blank" />
          </FileInput>
        </SimpleForm>
      </Card>
    </Grid>
  );
};

export default PageTypeStyle;
