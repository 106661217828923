// in src/App.js
import * as React from "react";
import { Admin, Resource, ListGuesser, EditGuesser } from "react-admin";
import Dashboard from "./Dashboard";
import { Route } from "react-router-dom";
import { tokenAuthProvider } from "./authProvider";
import myDataProvider from "./addUploadFeature";
import UserList from "./users/UserList";
import UserEdit from "./users/UserEdit";
import UserCreate from "./users/UserCreate";
import UserChnagePassword from "./users/UserChnagePassword";
import PageTypeCreate from "./page-types/PageTypeCreate";
import PageTypeEdit from "./page-types/PageTypeEdit";
import PageTypeStyle from "./page-types/PageTypeStyle";
import PageTypeFile from "./page-types/PageTypeFile";
import PageTypeList from "./page-types/PageTypeList";
import PageFieldCreate from "./page-fields/PageFieldCreate";
import PageFieldList from "./page-fields/PageFieldList";
import PageFieldEdit from "./page-fields/PageFieldEdit";
import PageEntityList from "./page-entities/PageEntityList";
import PageEntityCreate from "./page-entities/PageEntityCreate";
import PageEntityEdit from "./page-entities/PageEntityEdit";
import PageEntityStatistics from "./page-entities/PageEntityStatistics";
import PageEntityBulkImport from "./page-entities/PageEntityBulkImport";
import PageEntityPrintingPage from "./page-entities/PageEntityPrintingPage";
import config from './config';
import LoginWithTheme from "./LoginPage";
import OrganizationPage from "./organization/OrganizationPage";
import PageTypeStatistics from "./page-types/PageTypeStatistics";
import CustomLayout from "./layout/Layout";
import mirgTheme from "./theme";
import MyProfilePage from "./user-profile/MyProfilePage";
import ChangePassword from './user-profile/ChangePassword';
import UserAccesList from "./user-acces/UserAccesList";
import UserAccesCreate from "./user-acces/UserAccesCreate";
import PageTypeTemplate from "./page-types/PageTypeTemplate";

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import TemplatesList from "./templates/TemplatesList";
import TemplatesEdit from "./templates/TemplatesEdit";
import TemplatesCreate from "./templates/TemplatesCreate";
import TemplateHistory from "./templates/TemplateHistory";
import PageTypeTemplateHistory from "./page-types/PageTypeTemplateHistory";

export const baseDomain = config.baseDomain();

const authProvider = tokenAuthProvider({
  obtainAuthTokenUrl: baseDomain + "/auth/token/login/",
});

const App = () => (
  <Admin
    // dashboard={Dashboard}
    dataProvider={myDataProvider}
    authProvider={authProvider}
    customRoutes={[
      <Route path="/page-types/:page_id/style" component={PageTypeStyle} />,
      <Route path="/page-types/:page_id/file" component={PageTypeFile} />,
      <Route path="/users/:uid/:token/" component={UserChnagePassword} />,
      <Route
        path="/page-field/:page_type/create"
        component={PageFieldCreate}
      />,
      <Route path="/page-type/:id/statistics" component={PageTypeStatistics} />,
      <Route path="/page-field/:page_type/:id" component={PageFieldEdit} />,
      <Route path="/page-field/:page_type" component={PageFieldList} />,
      <Route path="/page-types/:page_type/bulk_import" component={PageEntityBulkImport} />,
      <Route path="/page-entity/:page_type/print" component={PageEntityPrintingPage} />,
      <Route path="/page-entity/:page_type/create" component={PageEntityCreate} />,
      <Route path="/page-entity/:page_type/:id/statistics" component={PageEntityStatistics} />,
      <Route path="/page-entity/:page_type/:id" component={PageEntityEdit} />,
      <Route path="/page-entity/:page_type" component={PageEntityList} />,
      <Route path="/organization" component={OrganizationPage} />,
      <Route path="/profile/change-password" component={ChangePassword} />,
      <Route path="/profile" component={MyProfilePage} />,
      <Route path="/user-acces/:page_type/create" component={UserAccesCreate} />,
      <Route path="/user-acces/:page_type" component={UserAccesList} />,
      <Route path="/page-types/:id/template/history" component={PageTypeTemplateHistory} />,
      <Route path="/page-types/:page_type/template" component={PageTypeTemplate} />,
      <Route path="/templates/:id/history" component={TemplateHistory} />,
    ]}
    layout={CustomLayout}
    loginPage={LoginWithTheme}
    theme={mirgTheme}
  >
    <Resource
      name="page-types"
      list={PageTypeList}
      edit={PageTypeEdit}
      create={PageTypeCreate}
    />
    <Resource name="organizations" />
    <Resource name="organization" list={OrganizationPage} />
    <Resource name="/auth/users/" />
    <Resource
      name="users"
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
    />
    <Resource name="page-field" />
    <Resource name="page-entity" />
    <Resource name="acces-log" />
    <Resource name="organizations/statistics" />
    <Resource name="page-types/statistics" />
    <Resource name="user-acces" />
    <Resource name="templates" list={TemplatesList} edit={TemplatesEdit} create={TemplatesCreate} />
  </Admin>
);

export default App;
