import * as React from "react";
import { List, Datagrid, TextField, TextInput, Filter, Pagination } from "react-admin";
import { Card, CardHeader, Grid } from "@material-ui/core";
import ListActionsExportAll from "../ListActionsExportAll";

const PageTypeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const PageTypeList = (props) => (
  <Grid>
    <Card>
      <CardHeader title="All the Web sites" />
      <List
        {...props}
        filters={<PageTypeFilter />}
        actions={<ListActionsExportAll />}
        title="Web sites"
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 500]} {...props} />}
        perPage={25}
      >
        <Datagrid
          rowClick={(id) => {
            return `/page-entity/${id}`;
          }}
        >
          <TextField source="name" />
          <TextField source="description" />
        </Datagrid>
      </List>
    </Card>
  </Grid>
);

export default PageTypeList;
