import * as React from "react";
import {
  TextInput,
  FileField,
  FileInput,
  BooleanField,
  BooleanInput,
  TextField
} from "react-admin";
import { useField, Field } from "react-final-form";
import RichTextInput from "ra-input-rich-text";
import PageTypeFile from "../page-types/PageTypeFile";
import PageFieldGrapesJS from "./PageFieldGrapesJS";
import { InputLabel } from '@material-ui/core';

const validateURL = (string) => {
  var urlregex = new RegExp(
    "^(http|https|ftp)://([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&amp;%$-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]+.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(/($|[a-zA-Z0-9.,?'\\+&amp;%$#=~_-]+))*$"
  );

  if (string!=undefined && !urlregex.test(string)) {
    return "This value is not a link";
  }

  return;
};

const GenerateInput = ({
  source,
  initialValue,
  emailUpdatebal,
  sftpUpdatable,
  sftpSetting,
  page_type,
  fileManagerFieldState,
  changeShowFileManger,
  changeGrapesEditor,
  disabled,
}) => {
  const { input, meta } = useField(source);
  if (changeShowFileManger) {
    changeShowFileManger(false);
  }

  const formatFileInput = (value) => {
    if (!value || typeof value === "string") {
      return { src: value };
    } else {
      return value;
    }
  };

  const type = input.name ? input.name.configuration.type : input.value.type;
  const global = input.value.global;
  const sourceName = input.name ? `f${input.name.id}` : "value";
  const labelName = input.name ? input.name.configuration.name : "value";

  if (disabled) {
    return <div></div>;
  }

  if (!global && !input.name) {
    if (global != null && type == "singleFile")
      return (
        <div>
          <BooleanInput
            source="emailUpdatebal"
            label="Can be updated via email"
            initialValue={emailUpdatebal != null ? emailUpdatebal : true}
            disabled={disabled}
          />
          <BooleanInput
            source="sftpUpdatable"
            label="Can be updated via sftp"
            initialValue={sftpUpdatable != null ? sftpUpdatable : true}
            disabled={disabled}
          />
          <TextInput
            source="sftpSetting"
            label="Enter here the sftp setting sting"
            initialValue={sftpSetting != null ? sftpSetting : ""}
            disabled={disabled}
            helperText="The string should use the following template: sftp://username:password@host:port/directory"
          />
        </div>
      );
    return <div></div>;
  }

  if (type == "text") {
    return (
      <TextInput
        source={sourceName}
        initialValue={initialValue}
        label={labelName}
        multiline
        fullWidth
        helperText="This is what is displayed on the popup"
        disabled={disabled}
      />
    );
  }

  if (type == "link") {
    return (
      <TextInput
        source={sourceName}
        initialValue={initialValue}
        label={labelName}
        type="url"
        validate={validateURL}
        helperText="This is what is displayed on the popup"
        disabled={disabled}
      />
    );
  }

  if (type == "phoneNumber") {
    return (
      <TextInput
        source={sourceName}
        initialValue={initialValue}
        label={labelName}
        helperText="This is what is displayed on the popup"
        disabled={disabled}
      />
    );
  }

  if (type == "identity") {
    return (
      <TextInput
        source={sourceName}
        initialValue={initialValue}
        label={labelName}
        helperText="This is a unique identifier for the QR"
        disabled={disabled}
      />
    );
  }

  if (type == "richText" && global) {
    return (
      <div style={{ marginTop: "15px" }}>
        <InputLabel>{labelName}</InputLabel>
        <PageFieldGrapesJS
          setEditor={changeGrapesEditor}
          data={{
            html: initialValue,
            css: initialValue
              ? input.name
                ? input.name.configuration.css
                : input.value.configuration.css
              : "",
          }}
        />
      </div>
    );
  }

  if (type == "richText" && !disabled) {
    return (
      <RichTextInput
        source={sourceName}
        initialValue={initialValue}
        label={labelName}
        helperText="This is what is displayed on the popup"
      />
    );
  }

  if (type == "singleFile" && !disabled) {
    return (
      <div>
        <FileInput
          source={sourceName}
          format={formatFileInput}
          label={sourceName == "value" ? "File" : labelName}
          initialValue={initialValue}
          accept="application/pdf"
        >
          <FileField source="src" title="File" target="_blank" />
        </FileInput>
        {global != null && (
          <div>
            <BooleanInput
              source="emailUpdatebal"
              label="Can be updated via email"
              initialValue={emailUpdatebal != null ? emailUpdatebal : true}
            />
            <BooleanInput
              source="sftpUpdatable"
              label="Can be updated via sftp"
              initialValue={sftpUpdatable != null ? sftpUpdatable : true}
            />
            <TextInput
              source="sftpSetting"
              label="Enter here the sftp setting sting"
              initialValue={sftpSetting != null ? sftpSetting : ""}
              disabled={disabled}
              helperText="The string should use the following template: sftp://username:password@host:port/directory"
            />
          </div>
        )}
      </div>
    );
  }

  if (type == "fileManager") {
    changeShowFileManger(true);
    return (
      <div>
        <Field
          name="value"
          initialValue={initialValue}
          defaultValue={fileManagerFieldState}
        >
          {({ input, meta }) => {
            input.onChange(
              fileManagerFieldState == "path"
                ? initialValue
                  ? initialValue
                  : "path"
                : fileManagerFieldState
            );
            return (
              <div>
                <p>To share a specific path select a folder</p>
                <TextInput
                  source="value"
                  label="The shared path will be"
                  value={input.value}
                  style={{ fontSize: "1rem", marginTop: "5px", color: "red" }}
                  inputProps={{ readOnly: true }}
                  error={!!(meta.touched && meta.error)}
                  helperText={meta.touched && meta.error}
                />
              </div>
            );
          }}
        </Field>
      </div>
    );
  }

  return <div></div>;
};

export default GenerateInput;
