import * as React from 'react';
import { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    useListContext,
    TopToolbar,
    CreateButton,
    Button,
    sanitizeListRestProps,
    usePermissions,
} from 'react-admin';
import IconEvent from '@material-ui/icons/Event';
import ExportButton from './ExportButton';

export const ListActionsExportAll = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        extraButtons,
        defaultFilter,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext(props);
    const {permissions} = usePermissions();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {hasCreate && ( resource!="page-types" || permissions) && <CreateButton basePath={basePath} />}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                label="Export ALL"
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
                defaultFilter={defaultFilter}
            />
            {extraButtons}
            {/* Add your custom actions */}
        </TopToolbar>
    );
};

export default ListActionsExportAll;
