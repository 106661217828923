import * as React from "react";
import {
  TextField,
  TextInput,
  Edit,
  ReferenceField,
  SimpleForm,
  SelectInput,
  required,
  useQuery,
  Loading,
  Error,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useMutation,
  ShowButton
} from "react-admin";
import { Card, CardHeader, Grid, Link, Button } from "@material-ui/core";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs-blocks-bootstrap4/dist/grapesjs-blocks-bootstrap4.min.js";
import { GrapesjsReact } from "grapesjs-react";
import fetchWithHeaders from "../fetchWithHeaders";
import { convertFileToBase64 } from "../addUploadFeature";
import grapesjsCustomCode from "grapesjs-custom-code";
import FieldsComponentsGrapesJSPlugin from "../FieldsComponentsGrapesJSPlugin";
import LinkBlockPluginGrapesJS from "../LinkBlockPluginGrapesJS";
import GenericDivPluginGrapesJS from "../GenericDivPluginGrapesJS";
import ToolbarDeleteConfirm from "../ToolbarDeleteConfirm";
import HistoryIcon from '@material-ui/icons/History';


const TemplatesToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};

export class TemplatesEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editor: {},
    };
    this.uploadGrapesJSFile = this.uploadGrapesJSFile.bind(this);
  }

  async uploadGrapesJSFile(e) {
    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    for (var i = 0; i < files.length; i++) {
      const file_base64 = await convertFileToBase64(files[i], false);
      fetchWithHeaders(
        "POST",
        "/grapesJS-images/",
        JSON.stringify({ image: file_base64, name: files[i].name })
      ).then((response) => {
        this.state.editor.AssetManager.add({
          type: "image",
          src: response.url,
        });
      });
    }
  }

  render() {
    return (
      <Card>
        <CardHeader title="Edit template" />
        <SimpleForm
          record={this.props.data}
          save={this.props.saveTemplate}
          toolbar={<TemplatesToolbar />}
          submitOnEnter={false}
          action="javascript: void(0)"
          toolbar={<ToolbarDeleteConfirm/>}
          basePath="/templates"
          resource={"templates"}
        >
          <TextInput source="name" validate={required()} />
          <ShowButton
            to={`/templates/${this.props.data.id}/history`}
            label="Recover from history"
            icon={<HistoryIcon />}
          />

          <p style={{ width: "100%" }}>
            To design the fileds portion of the template you can use the "Field
            container" section. The "All fields" is the space where all the
            fields will be placed.
          </p>
          <p style={{ width: "100%" }}>
            To design how a single field looks you need to use the other
            elements from the "Field components" section. The inside of "All
            fields" will be used to show each field of the web page.
          </p>
          <GrapesjsReact
            id="grapesjs-react"
            plugins={[
              "grapesjs-blocks-bootstrap4",
              grapesjsCustomCode,
              FieldsComponentsGrapesJSPlugin,
              LinkBlockPluginGrapesJS,
              GenericDivPluginGrapesJS,
            ]}
            canvas={{
              styles: [
                "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css",
              ],
              scripts: [
                "https://code.jquery.com/jquery-3.3.1.slim.min.js",
                "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js",
                "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js",
              ],
            }}
            assetManager={{
              uploadFile: this.uploadGrapesJSFile,
            }}
            storageManager={false}
            cssIcons="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
            onInit={(editor) => {
              fetchWithHeaders("GET", "/grapesJS-images").then((response) => {
                const realAssets = response.map((x) => x.image);
                editor.AssetManager.add(realAssets);
              });
              this.setState({ editor: editor });
              this.props.setEditor(editor);
            }}
            components={this.props.data.html}
            style={this.props.data.css}
          />
        </SimpleForm>
      </Card>
    );
  }
}

export default (props) => {
  const [editor, setEditor] = React.useState();
  const [mutate] = useMutation();
  const notify = useNotify();
  const redirectTo = useRedirect();

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "templates",
    payload: { id: props.id },
  });

  const saveTemplate = ({ id, name }) => {
    return mutate(
      {
        type: "update",
        resource: "templates",
        payload: {
          id: id,
          data: {
            name: name,
            html: editor.getHtml(),
            css: editor.getCss(),
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`You just updated the template "${data.name}"`);
          redirectTo(`/templates/`);
        },
        onFailure: (error) => {
          var allErrors = "";
          for (const property in error.body) {
            allErrors += `${property}:${error.body[property]},`;
          }
          notify(`Error: ${allErrors}`);
        },
      }
    );
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <TemplatesEdit
      data={data}
      setEditor={setEditor}
      saveTemplate={saveTemplate}
    />
  );
};
