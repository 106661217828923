import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ShowButton, useRedirect } from "react-admin";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Button, Link, IconButton } from "@material-ui/core";

export default function MoreMenu({record}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const redirectTo = useRedirect();

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <span>
      <Button
        aria-controls="more-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon/>
      </Button>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      <ShowButton
        to={`/page-entity/${record.page_type}/${record.id}/statistics`}
        label="Statistics"
        icon={<EqualizerIcon/>}
      />
      </Menu>
    </span>
  );
}