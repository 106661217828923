import * as React from "react";
import { List, Datagrid, TextField, Filter, TextInput, Pagination } from "react-admin";
import ButtonField from "../ButtonField";
import ListActionsExportAll from "../ListActionsExportAll";

const UsersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const UserList = (props) => {
  return (
    <List
      {...props}
      filters={<UsersFilter />}
      actions={<ListActionsExportAll />}
      pagination={
        <Pagination rowsPerPageOptions={[25, 50, 100, 500]} {...props} />
      }
      perPage={25}
    >
      <Datagrid rowClick="edit">
        <TextField source="username" />
        <TextField source="email" />
        <ButtonField />
      </Datagrid>
    </List>
  );
};

export default UserList;
