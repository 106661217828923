import * as React from "react";
import {
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useMutation,
  Loading,
  Button,
} from "react-admin";
import {
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs-blocks-bootstrap4/dist/grapesjs-blocks-bootstrap4.min.js";
import { GrapesjsReact } from "grapesjs-react";
import fetchWithHeaders from "../fetchWithHeaders";
import { convertFileToBase64 } from "../addUploadFeature";
import grapesjsCustomCode from "grapesjs-custom-code";
import FieldsComponentsGrapesJSPlugin from "../FieldsComponentsGrapesJSPlugin";
import LinkBlockPluginGrapesJS from "../LinkBlockPluginGrapesJS";
import GenericDivPluginGrapesJS from "../GenericDivPluginGrapesJS";
import InboxIcon from "@material-ui/icons/Inbox";
import { makeStyles } from "@material-ui/core/styles";
import { format, parseISO } from "date-fns";
import ReactDiffViewer from "react-diff-viewer";
import BackButtonToolbar from "../BackButtonToolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  mainDiv: {
    marginLeft: 20,
  },
  card: {
    marginBottom: 20,
  },
  saveButton: {
    marginLeft: 20,
    marginBottom: 20,
    marginTop: 10,
  },
}));

const HistoryList = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.mainDiv}>
      <p>Select a date to restore template</p>
      <List
        className={classes.root}
        component="nav"
        aria-label="main mailbox folders"
      >
        {props.history.map((element, index) => {
          return (
            <ListItem
              button
              selected={props.selected === index}
              onClick={(event) => props.setSelected(index)}
              key={index}
            >
              <ListItemText
                primary={format(
                  parseISO(element.history_date),
                  "dd-MM-yyyy HH:mm"
                )}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export class TemplatesHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editor: {},
      history: [],
      selected: 0,
      fetched: false,
      selected_html: "",
      selected_css: "",
      base_html: "",
      base_css: "",
    };
    this.fetchHistory = this.fetchHistory.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.fetchBase = this.fetchBase.bind(this);
  }

  componentDidMount() {
    this.fetchHistory();
    this.fetchBase();
  }

  setSelected(selected) {
    this.setState({ selected: selected });
    const historyId = this.state.history[selected].history_id;
    fetchWithHeaders(
      "GET",
      `/templates/${this.props.match.params.id}/history/?history_id=${historyId}`
    ).then((response) => {
      this.setState({
        selected_html: response.html,
        selected_css: response.css,
      });
    });
  }

  async fetchHistory() {
    fetchWithHeaders(
      "GET",
      `/templates/${this.props.match.params.id}/history/`
    ).then((response) => {
      this.setState({ history: response, fetched: true });
    });
  }

  async fetchBase() {
    fetchWithHeaders("GET", `/templates/${this.props.match.params.id}`).then(
      (response) => {
        this.setState({
          base_html: response.html,
          base_css: response.css,
          selected_html: response.html,
          selected_css: response.css,
        });
      }
    );
  }

  render() {
    if (!this.state.base_html && !this.state.fetched) return <Loading />;
    if (this.state.fetched && this.state.history.length == 0)
      return (
        <Grid>
          <BackButtonToolbar {...this.props} />
          <Card className={this.props.classes.card}>
            <CardHeader title="Recover template from history" />
            <div className={this.props.classes.mainDiv}>
              <p>No old versions are available in history</p>
            </div>
          </Card>
        </Grid>
      );
    return (
      <Grid>
        <BackButtonToolbar {...this.props} />
        <Card className={this.props.classes.card}>
          <CardHeader title="Recover template from history" />
          <HistoryList
            history={this.state.history}
            setSelected={this.setSelected}
            selected={this.state.selected}
          />
          <SaveButton
            label="Restore"
            disabled={this.state.selected == 0}
            className={this.props.classes.saveButton}
            handleSubmitWithRedirect={(e) => {
              this.props.saveTemplate(
                this.props.match.params.id,
                this.state.selected_html,
                this.state.selected_css
              );
            }}
          />
        </Card>
        <Card className={this.props.classes.card}>
          <CardHeader title="HTML differences" />
          <ReactDiffViewer
            oldValue={this.state.base_html}
            newValue={this.state.selected_html}
            splitView={true}
          />
        </Card>
        <Card className={this.props.classes.card}>
          <CardHeader title="CSS differences" />
          <ReactDiffViewer
            oldValue={this.state.base_css}
            newValue={this.state.selected_css}
            splitView={true}
          />
        </Card>
      </Grid>
    );
  }
}

export default (props) => {
  const [editor, setEditor] = React.useState();
  const [mutate] = useMutation();
  const notify = useNotify();
  const redirectTo = useRedirect();

  const classes = useStyles();

  const saveTemplate = (id, html, css) => {
    return mutate(
      {
        type: "update",
        resource: "templates",
        payload: {
          id: id,
          data: {
            html: html,
            css: css,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`You just updated the template "${data.name}"`);
          redirectTo(`/templates/${data.id}`);
        },
        onFailure: (error) => {
          var allErrors = "";
          for (const property in error.body) {
            allErrors += `${property}:${error.body[property]},`;
          }
          notify(`Error: ${allErrors}`);
        },
      }
    );
  };

  //   if (loading) return <Loading />;
  //   if (error) return <Error error={error} />;
  //   if (!data) return null;

  return (
    <TemplatesHistory
      setEditor={setEditor}
      saveTemplate={saveTemplate}
      classes={classes}
      {...props}
    />
  );
};
