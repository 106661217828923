import * as React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import {
  SimpleForm,
  FileInput,
  FileField,
  Toolbar,
  ShowButton,
  NumberInput,
  BooleanInput,
  useMutation,
  useNotify,
  useRedirect,
  useQuery,
  Loading,
  Error,
  SaveButton
} from "react-admin";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { ColorInput } from "react-admin-color-input";
import { convertFileToBase64 } from "../addUploadFeature";
import { useField } from "react-final-form";
import PrintIcon from '@material-ui/icons/Print';

const formatFileInput = (value) => {
  if (!value || typeof value === "string") {
    return { src: value };
  } else {
    return value;
  }
};

const convertBase64ToFile = (dataurl, filename) => {
  const bstr = atob(dataurl);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: "application/pdf" });
};

const validatePrintForm = (values) => {
  const errors = {};
  if(values.page_width<=0){
    errors.page_width=["Page width needs to be >0"];
  }
  if(values.page_height<=0){
    errors.page_height=["Page height needs to be >0"];
  }
  if(values.max_qr_on_row<=0){
    errors.max_qr_on_row=["Max qr on row needs to be >0"];
  }
  if(values.max_rows<=0){
    errors.max_rows=["Max rows needs to be >0"];
  }
  if(values.width_card<=0){
    errors.width_card=["Width card needs to be >0"];
  }
  if(values.height_card<=0){
    errors.height_card=["Height card needs to be >0"];
  }
  if(values.spacing_cards<=0){
    errors.spacing_cards=["Spacing cards needs to be >0"];
  }
  if (values.show_logo && !values.print_logo) {
    errors.print_logo = ["You need to upload a logo if 'Show logo' is active"];
  }
  if(!values.print_all && !values.page_entities){
    errors.page_entities = ["You need to upload a xlsx file if 'Print all' is inactive"];
  }
  if(values.max_qr_on_row && !Number.isInteger(values.max_qr_on_row)){
    errors.max_qr_on_row = ["This field should be an integer number"]
  }
  if(values.max_rows && !Number.isInteger(values.max_rows)){
    errors.max_rows = ["This field should be an integer number"]
  }
  if(values.page_height && !Number.isInteger(values.page_height)){
    errors.page_height = ["This field should be an integer number"]
  }
  if(values.page_width && !Number.isInteger(values.page_width)){
    errors.page_width = ["This field should be an integer number"]
  }
  return errors;
};

const ShowLogoUpload = ({ source, initialValue }) => {
  const { input, meta } = useField(source);

  const show = input.value.show_logo;

  if (show) {
    return (
      <FileInput source="print_logo" label="Logo" accept="image/*" initialValue={initialValue} format={formatFileInput}>
        <FileField source="src" title="Logo" target="_blank" />
      </FileInput>
    );
  }

  return <div></div>;
};

const ShowXlsxUpload = ({ source }) => {
  const { input, meta } = useField(source);

  const show = !input.value.print_all;

  if (show) {
    return (
      <FileInput source="page_entities" label="Pages" helperText="Upload an .xlsx file that contains a slug on every row">
        <FileField source="src" title="Logo" target="_blank" />
      </FileInput>
    );
  }

  return <div></div>;
};

const PrintToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Print" disabled={props.pristine} icon={<PrintIcon />} disabled={false}/>
    </Toolbar>
  );
};

const PageEntityBulkInportActions = ({ history }) => {
  return (
    <Toolbar>
      <ShowButton
        icon={<ArrowBack />}
        label="Back"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          history.goBack();
        }}
        onAuxClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          history.goBack();
        }}
      />
    </Toolbar>
  );
};

export const PageEntityPrintingPage = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [mutate] = useMutation();
  const [loadingPrint, setLoadingPrint] = React.useState(false);


  const page_type = props.match.params.page_type;

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "page-types",
    payload: { id: page_type },
  });

  const makePrint = async ({
    page_width,
    page_height,
    max_qr_on_row,
    max_rows,
    print_background_color,
    print_qr_color,
    print_url_color,
    show_logo,
    show_url,
    print_qr_background_color,
    show_row_number,
    width_card,
    spacing_cards,
    print_logo,
    page_entities,
    save_as_default,
    height_card
  }) => {
    const logo_file = (!print_logo || (typeof print_logo) === "string") ? undefined  : await convertFileToBase64(print_logo) ;
    const page_entities_file = page_entities && await convertFileToBase64(page_entities);
    setLoadingPrint(true);
    return mutate(
      {
        type: "create",
        resource: "page-entity/print_style",
        payload: {
          data: {
            page_type: page_type,
            page_width: page_width,
            page_height: page_height,
            print_background_color: print_background_color,
            print_qr_color: print_qr_color,
            print_url_color: print_url_color,
            show_logo: show_logo,
            show_url: show_url,
            print_qr_background_color: print_qr_background_color,
            show_row_number: show_row_number,
            max_qr_on_row: max_qr_on_row,
            max_rows: max_rows,
            width_card: width_card,
            spacing_cards: spacing_cards,
            logo: logo_file,
            page_entities: page_entities_file,
            save_as_default: save_as_default,
            height_card: height_card
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`The print was made!`);
          const file = convertBase64ToFile(data.pdf, "rezult.pdf");
          const element = document.createElement("a");
          element.href = URL.createObjectURL(file);
          element.download = "qr.pdf";
          document.body.appendChild(element);
          element.click();
          localStorage.removeItem("entities_file");
          setLoadingPrint(false);
          redirectTo(`/page-entity/${page_type}`);
        },
        onFailure: (error) => {
          setLoadingPrint(false);
          var allErrors = "";
          for (const property in error.body) {
            allErrors += `${property}:${error.body[property]},`;
          }
          notify(`Error: ${allErrors}`);
        },
      }
    );
  };

  if (loading || loadingPrint) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;


  return (
    <Grid>
      <PageEntityBulkInportActions {...props} />
      <Card>
        <CardHeader title="Printing QR page" />
        <SimpleForm save={makePrint} record={data} toolbar={<PrintToolbar />} validate={validatePrintForm}>
          <BooleanInput source="print_all" defaultValue={true} helperText="If it is on, you will print all the pages. If is off, you can upload a .xlsx file that contains a slug on every row"/>
          <ShowXlsxUpload />
          <NumberInput source="page_width" label="Page width in cm" min={1} />
          <NumberInput source="page_height" label="Page height in cm" min={1}/>
          <NumberInput source="max_qr_on_row" label="Max QRs on row" min={1}/>
          <NumberInput source="max_rows" label="Max number of rows per page" min={1}/>
          <NumberInput source="width_card" label="Width of the card in cm" min={1}/>
          <NumberInput source="height_card" label="Height of the card in cm" min={1}/>
          <NumberInput source="spacing_cards" label="Spacing between cards in cm" min={1}/>
          <ColorInput source="print_background_color" picker="Sketch" label="Background Color"/>
          <ColorInput source="print_qr_color" picker="Sketch" label="QR Color"/>
          <ColorInput source="print_url_color" picker="Sketch" label="URL Color" />
          <ColorInput source="print_qr_background_color" picker="Sketch" label="QR Background Color" />
          <BooleanInput source="show_url" defaultValue={true} helperText="If it is on, the URL which is in the QR is shown"/>
          <BooleanInput source="show_row_number" defaultValue={true} helperText="If it is on, the number of each row will be shown in PDF"/>
          <BooleanInput source="show_logo" defaultValue={false} initialValue={false} helperText="If it is on, each QR will have the uploaded logo"/>
          <ShowLogoUpload initialValue={data.print_logo}/>
          <BooleanInput source="save_as_default" defaultValue={false} helperText="If it is on, all the above settings will be saved"/>
        </SimpleForm>
      </Card>
    </Grid>
  );
};

export default PageEntityPrintingPage;
