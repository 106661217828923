import * as React from "react";
import {
  Edit,
  ReferenceField,
  SimpleForm,
  SelectInput,
  required,
  useQuery,
  Loading,
  Error,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useMutation,
  ShowButton
} from "react-admin";
import { Card, CardHeader, Grid } from "@material-ui/core";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs-blocks-bootstrap4/dist/grapesjs-blocks-bootstrap4.min.js";
import { GrapesjsReact } from "grapesjs-react";
import fetchWithHeaders from "../fetchWithHeaders";
import { convertFileToBase64 } from "../addUploadFeature";
import PageTypeToolbar from "./ButtonToolbar";
import Button from "@material-ui/core/Button";
import FieldsComponentsGrapesJSPlugin from "../FieldsComponentsGrapesJSPlugin";
import grapesjsCustomCode from "grapesjs-custom-code";
import LinkBlockPluginGrapesJS from "../LinkBlockPluginGrapesJS";
import GenericDivPluginGrapesJS from "../GenericDivPluginGrapesJS";
import HistoryIcon from '@material-ui/icons/History';

const TemplatesToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};

export class PageTypeTemplateEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editor: {},
    };
    this.uploadGrapesJSFile = this.uploadGrapesJSFile.bind(this);
    this.addFieldBodyComponent = this.addFieldBodyComponent.bind(this);
    this.templateSelected = React.createRef();
  }

  addFieldBodyComponent(editor) {
    editor.BlockManager.add("field").set({
      label: `<div>
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="square" style="width:45px" class="svg-inline--fa fa-square fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"></path></svg>
          <div>Single field</div>
        </div>`,
      category: { id: 1, label: "Field containers", order: -2 },
      content: {
        type: "field",
        classes: ["field", "col"],
      },
    });

    var domc = editor.DomComponents;
    const defaultType = domc.getType("default");
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;

    domc.addType("field", {
      model: defaultModel.extend(
        {
          defaults: Object.assign({}, defaultModel.prototype.defaults, {
            "custom-name": "Single field",
            tagName: "div",
            droppable: true,
            traits: [
              {
                type: "select",
                name: "field-id",
                options: this.props.fields.map((x) => {
                  return { id: x.id, name: x.configuration.name };
                }),
                label: "Field",
              },
            ].concat(defaultModel.prototype.defaults.traits),
          }),
        },
        {
          isComponent(el) {
            if (el && el.classList && el.classList.contains("field")) {
              return { type: "field" };
            }
          },
        }
      ),
      view: defaultView,
    });
  }

  async uploadGrapesJSFile(e) {
    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    for (var i = 0; i < files.length; i++) {
      const file_base64 = await convertFileToBase64(files[i], false);
      fetchWithHeaders(
        "POST",
        "/grapesJS-images/",
        JSON.stringify({ image: file_base64, name: files[i].name })
      ).then((response) => {
        this.state.editor.AssetManager.add({
          type: "image",
          src: response.url,
        });
      });
    }
  }

  render() {
    return (
      <Grid>
        <PageTypeToolbar pageTypeId={this.props.data.id} initialValue={6} />
        <Card>
          <CardHeader
            title={`Edit template of the web site ${this.props.data.name}`}
          />
          <SimpleForm
            record={this.props.data}
            save={this.props.saveTemplate}
            toolbar={<TemplatesToolbar />}
            action="javascript: void(0)"
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <SelectInput
                source="Select a template"
                choices={this.props.templates.map((x) => {
                  return { id: x.id, name: x.name };
                })}
                inputRef={this.templateSelected}
                helperText="If you want to load an template"
              />

              <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: "10px", marginLeft: "10px" }}
                onClick={() => {
                  const idTemplate = this.templateSelected.current.value;
                  for (var i = 0; i < this.props.templates.length; i++) {
                    if (this.props.templates[i].id == idTemplate) {
                      this.state.editor.setComponents(
                        this.props.templates[i].html
                      );
                      this.state.editor.setStyle(this.props.templates[i].css);
                    }
                  }
                }}
              >
                Load template
              </Button>
            </div>
            <ShowButton
              to={`/page-types/${this.props.data.id}/template/history`}
              label="Recover from history"
              icon={<HistoryIcon />}
            />
            <p style={{ width: "100%" }}>
              To design the fileds portion of the template you can use the
              "Field container" section. The "All fields" is the space where all
              the fields will be placed.
            </p>
            <p style={{ width: "100%" }}>
              To design how a single field looks you need to use the other
              elements from the "Field components" section. The inside of "All
              fields" will be used to show each field of the web page.
            </p>
            <p style={{ width: "100%" }}>
              If you want to use a different design for each field you can you
              the "Single field". Each "Single field" is associate with one
              field. In that space, you can also use the other elements from the
              section.
            </p>
            <p style={{ width: "100%" }}>
              Don't use a "Single Field" inside a "All fields".
            </p>
            <GrapesjsReact
              id="grapesjs-react"
              plugins={[
                "grapesjs-blocks-bootstrap4",
                FieldsComponentsGrapesJSPlugin,
                this.addFieldBodyComponent,
                grapesjsCustomCode,
                LinkBlockPluginGrapesJS,
                GenericDivPluginGrapesJS,
              ]}
              canvas={{
                styles: [
                  "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css",
                ],
                scripts: [
                  "https://code.jquery.com/jquery-3.3.1.slim.min.js",
                  "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js",
                  "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js",
                ],
              }}
              assetManager={{
                uploadFile: this.uploadGrapesJSFile,
              }}
              storageManager={false}
              cssIcons="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
              onInit={(editor) => {
                fetchWithHeaders("GET", "/grapesJS-images").then((response) => {
                  const realAssets = response.map((x) => x.image);
                  editor.AssetManager.add(realAssets);
                });
                this.setState({ editor: editor });
                this.props.setEditor(editor);
              }}
              components={this.props.data.template_html}
              style={this.props.data.template_css}
            />
          </SimpleForm>
        </Card>
      </Grid>
    );
  }
}

export default (props) => {
  const [editor, setEditor] = React.useState();
  const [mutate] = useMutation();
  const notify = useNotify();
  const redirectTo = useRedirect();

  const id = props.match.params.page_type;

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "page-types",
    payload: { id: id },
  });

  const templates = useQuery({
    type: "getList",
    resource: "templates",
    payload: { pagination: {}, sort: {}, filter: {}, order: { id: "ASC" } },
  });

  const fields = useQuery({
    type: "getList",
    resource: "page-field",
    payload: {
      pagination: { page: 1, perPage: 50 },
      sort: {},
      filter: { page_type__id: id },
      order: { order: "ASC" },
    },
  });

  const saveTemplate = () => {
    return mutate(
      {
        type: "update",
        resource: "page-types",
        payload: {
          id: id,
          data: {
            template_html: editor.getHtml(),
            template_css: editor.getCss(),
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`You just updated the template of web site "${data.name}"`);
        },
        onFailure: (error) => {
          var allErrors = "";
          for (const property in error.body) {
            allErrors += `${property}:${error.body[property]},`;
          }
          notify(`Error: ${allErrors}`);
        },
      }
    );
  };

  if (loading || templates.loading || fields.loading) return <Loading />;
  if (error || templates.error || fields.error)
    return (
      <Error
        error={error ? error : templates.error ? templates.error : fields.error}
      />
    );
  if (!data) return null;

  return (
    <PageTypeTemplateEdit
      data={data}
      setEditor={setEditor}
      saveTemplate={saveTemplate}
      templates={templates.data}
      fields={fields.data}
    />
  );
};
