import * as React from "react";
import { Button, useRedirect, useMutation, useNotify} from "react-admin";
import { baseDomain } from "./App";

const ButtonField = ({ source, record }) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const [mutate] = useMutation();

  return (
    <Button
      label="Reset password"
      onClick={(e) => {
        e.stopPropagation();
        fetch(baseDomain + "/auth/users/reset_password/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: record.email }),
        })
          .then(() => {
            notify(`Password reseted`);
          })
          .catch((e) => {
            notify(`Error: "${e}"`);
          });
      }}
    />
  );
};

export default ButtonField;
