import * as React from "react";
import {
  TextInput,
  SimpleForm,
  Create,
  PasswordInput,
  useMutation,
  useNotify,
  useRedirect,
  required
} from "react-admin";
import { Card, CardHeader } from "@material-ui/core";

export const UserCreate = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [mutate] = useMutation();

  const createUser = ({ username, email, password, confirmPassword }) => {
    if (password === confirmPassword) {
      return mutate(
        {
          type: "create",
          resource: "auth/users",
          payload: {
            data: {
              username: username,
              email: email,
              password: password,
            },
          },
        },
        {
          onSuccess: ({ data }) => {
            notify(`You just created the user "${data.username}"`);
            redirectTo(`/users`);
          },
          onFailure: (error) => {
            var allErrors = "";
            for (const property in error.body) {
              allErrors += `${property}:${error.body[property]},`;
            }
            notify(`Error: ${allErrors}`);
          },
        }
      );
    } else {
      notify(`The 2 password doesn't match`);
    }
  };

  return (
    <Create {...props}>
      <Card>
        <CardHeader title="Create a new user" />
        <SimpleForm save={createUser}>
          <TextInput source="username" validate={required()}/>
          <TextInput source="email" type="email"/>
          <PasswordInput source="password" validate={required()}/>
          <PasswordInput source="confirmPassword" validate={required()}/>
        </SimpleForm>
      </Card>
    </Create>
  );
};

export default UserCreate;
