import drfProvider, {
  fetchJsonWithAuthToken,
} from "ra-data-django-rest-framework";
import config from './config';

export const baseDomain = config.baseDomain();

const dataProvider = drfProvider(baseDomain, fetchJsonWithAuthToken);

const myDataProvider = {
  ...dataProvider,
  create: (resource, params) => {
    if (
      resource !== "page-field" ||
      params.data.configuration.type !== "singleFile" ||
      !params.data.configuration.value
    ) {
      // fallback to the default implementation
      return dataProvider.create(resource, params);
    }
    /**
     * For posts update only, convert uploaded image in base 64 and attach it to
     * the `picture` sent property, with `src` and `title` attributes.
     */

    // Freshly dropped pictures are File objects and must be converted to base64 strings
    const newPictures = params.data.configuration.value.filter(
      (p) => p.rawFile instanceof File
    );
    const formerPictures = params.data.configuration.value.filter(
      (p) => !(p.rawFile instanceof File)
    );

    return Promise.all(newPictures.map(convertFileToBase64))
      .then((base64Pictures) =>
        base64Pictures.map((picture64) => ({
          src: picture64,
          title: `${params.data.configuration.value.File}`,
        }))
      )
      .then((transformedNewPictures) =>
        dataProvider.create(resource, {
          ...params,
          data: {
            ...params.data,
            configuration: {
              ...params.data.configuration,
              value: transformedNewPictures[0].src,
            },
          },
        })
      );
  },
  update: (resource, params) => {
    if (
      (resource !== "page-types" || !params.data.logo) &&
      (resource !== "page-field" ||
        params.data.configuration.type !== "singleFile" ||
        !params.data.configuration.value)
    ) {
      if (resource === "page-types" && params.data["logo"]) {
        delete params.data["logo"];
      }
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }
    
    if (resource === "page-types") {
      delete params.data["print_logo"];
    }
    const files =
      resource === "page-types"
        ? [params.data.logo]
        : params.data.configuration.value;

    const title =
      resource === "page-types"
        ? params.data.title
        : params.data.configuration.value.File;

    const newPictures = files.filter((p) => p.rawFile instanceof File);
    const formerPictures = files.filter((p) => !(p.rawFile instanceof File));

    if (newPictures.length == 0) {
      if (resource === "page-types") {
        delete params.data["logo"];
      } else {
        params.data.configuration["value"] = params.data.configuration.value[0];
      }
      return dataProvider.update(resource, params);
    }

    return Promise.all(newPictures.map(convertFileToBase64))
      .then((base64Pictures) =>
        base64Pictures.map((picture64) => ({
          src: picture64,
          title: `${title}`,
        }))
      )
      .then((transformedNewPictures) => {
        if (resource == "page-types") {
          return dataProvider.update(resource, {
            ...params,
            data: {
              ...params.data,
              logo: transformedNewPictures[0].src,
            },
          });
        }
        if (resource == "page-field") {
          return dataProvider.update(resource, {
            ...params,
            data: {
              ...params.data,
              configuration: {
                ...params.data.configuration,
                value: transformedNewPictures[0].src,
              },
            },
          });
        }
      });
  },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
 export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile ? file.rawFile : file);
  });

export default myDataProvider;
