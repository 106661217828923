import * as React from "react";
import {
  TextField,
  TextInput,
  Edit,
  ReferenceField,
  SimpleForm,
  SelectInput,
  required,
  useQuery,
  Loading,
  Error,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useMutation,
} from "react-admin";
import { Card, CardHeader, Grid } from "@material-ui/core";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs-blocks-bootstrap4/dist/grapesjs-blocks-bootstrap4.min.js";
import { GrapesjsReact } from "grapesjs-react";
import fetchWithHeaders from "../fetchWithHeaders";
import { convertFileToBase64 } from "../addUploadFeature";
import grapesjsCustomCode from "grapesjs-custom-code";
import FieldsComponentsGrapesJSPlugin from "../FieldsComponentsGrapesJSPlugin";
import LinkBlockPluginGrapesJS from "../LinkBlockPluginGrapesJS";
import GenericDivPluginGrapesJS from "../GenericDivPluginGrapesJS";

export class PageFieldGrapesJS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editor: {},
    };
    this.uploadGrapesJSFile = this.uploadGrapesJSFile.bind(this);
  }

  async uploadGrapesJSFile(e) {
    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    for (var i = 0; i < files.length; i++) {
      const file_base64 = await convertFileToBase64(files[i], false);
      fetchWithHeaders(
        "POST",
        "/grapesJS-images/",
        JSON.stringify({ image: file_base64, name: files[i].name })
      ).then((response) => {
        this.state.editor.AssetManager.add({
          type: "image",
          src: response.url,
        });
      });
    }
  }

  render() {
    return (
      <GrapesjsReact
        id="grapesjs-react"
        plugins={["grapesjs-blocks-bootstrap4", grapesjsCustomCode, LinkBlockPluginGrapesJS, GenericDivPluginGrapesJS]}
        canvas={{
          styles: [
            "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css",
          ],
          scripts: [
            "https://code.jquery.com/jquery-3.3.1.slim.min.js",
            "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js",
            "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js",
          ],
        }}
        assetManager={{
          uploadFile: this.uploadGrapesJSFile,
        }}
        storageManager={false}
        cssIcons="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        onInit={(editor) => {
          fetchWithHeaders("GET", "/grapesJS-images").then((response) => {
            const realAssets = response.map(
              (x) => x.image
            );
            editor.AssetManager.add(realAssets);
          });
          this.setState({ editor: editor });
          this.props.setEditor(editor);
        }}
        components={this.props.data.html}
        style={this.props.data.css}
      />
    );
  }
}

export default (props) => {
  return (
    <PageFieldGrapesJS
      data={props.data}
      setEditor={props.setEditor}
    />
  );
};
