import * as React from "react";
import { SimpleForm, PasswordInput, useNotify, useRedirect, required } from "react-admin";
import { Card, Grid, CardHeader } from "@material-ui/core";
import { baseDomain } from "../App";
import UseProfileNavigation from "./UserProfileNavigation";

export const ChangePassword = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  const changePassword = ({
    currentPassword,
    newPassword,
    confirmPassword,
  }) => {
    if (newPassword === confirmPassword) {
      fetch(baseDomain + "/auth/users/set_password/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AUTHORIZATION: "Token " + localStorage.token,
        },
        body: JSON.stringify({
          new_password: newPassword,
          re_new_password: confirmPassword,
          current_password: currentPassword,
        }),
      })
        .then((response) => {if (response.ok) return undefined; return response.json();})
        .then((data) => {
          if (data) {
            var allErrors = "";
            for (const property in data) {
              allErrors += `${property}:${data[property]},`;
            }
            notify(`Error: ${allErrors}`);
          } else {
            notify(`Password changed`);
            redirectTo("/");
          }
        });
    } else {
      notify(`The 2 password doesn't match`);
    }
  };

  return (
    <Grid>
      <UseProfileNavigation initialValue={1} />
      <Card>
        <CardHeader title="Change password" />
        <SimpleForm save={changePassword}>
          <PasswordInput source="currentPassword" label="Current password" validate={required()} />
          <PasswordInput source="newPassword" label="New password" validate={required()} />
          <PasswordInput
            source="confirmPassword"
            label="Confirm new password"
            validate={required()}
          />
        </SimpleForm>
      </Card>
    </Grid>
  );
};

export default ChangePassword;
