import * as React from "react";
import {
  TextField,
  TextInput,
  Edit,
  ReferenceField,
  SimpleForm,
  SelectInput,
  required,
} from "react-admin";
import { Card, CardHeader, Grid } from "@material-ui/core";
import PageTypeToolbar from "./ButtonToolbar";
import ToolbarDeleteConfirm from "../ToolbarDeleteConfirm";

export const PageTypeEdit = (props) => {
  return (
    <Grid>
      <PageTypeToolbar pageTypeId={props.id} initialValue={3} />
      <Card>
        <CardHeader title="Change the basic properties of the web site" />
        <Edit {...props} title="Web site">
          <SimpleForm toolbar={<ToolbarDeleteConfirm/>}>
            <TextInput source="name" helperText="The name of the web site" validate={required()} />
            <TextInput source="description" helperText="A short description of the web site" validate={required()} />
            <TextInput source="page_title" helperText="The title that will be shown in each QR" parse={(value)=>(value)}/>
            <TextInput source="header_text" helperText="The first text that will be shown in each QR" parse={(value)=>(value)}/>
            <TextInput source="header_link" helperText="You can add a link in the second row of the QR page" parse={(value)=>(value)} />
            <TextInput source="header_link_text"  helperText="You can add a link text in the second row of the QR page" parse={(value)=>(value)} />
            <SelectInput
              source="template_name"
              choices={[
                { id: "lexauto.html", name: "lexauto.html" },
                { id: "tp.html", name: "tp.html" },
                { id: "days.html", name: "days.html" },
              ]}
              helperText="The template describes how each QR page will look"
            />
            <ReferenceField source="organization" reference="organizations">
              <TextField source="name" disabled />
            </ReferenceField>
          </SimpleForm>
        </Edit>
      </Card>
    </Grid>
  );
};

export default PageTypeEdit;
