import * as React from "react";
import { Card, CardHeader, Grid, CardContent } from "@material-ui/core";
import { baseDomain } from "../App";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PageTypeToolbar from "./ButtonToolbar";
import ListActionsExportAll from "../ListActionsExportAll";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  FunctionField,
  DateField,
} from "react-admin";

const dateToStringForAPI = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return year + "-" + month + "-" + day;
};

const types = {
  PC: "Computer",
  MB: "Mobile",
  TB: "Tablet",
};

export class PageTypeStatistics extends React.Component {
  constructor(props) {
    const today = new Date();
    super(props);
    this.state = {
      date: {},
      dateStart: new Date(today.setMonth(today.getMonth() - 1)),
      dateStop: new Date(),
      page_type_name: "name",
    };
    this.changeDateStart = this.changeDateStart.bind(this);
    this.changeDateStop = this.changeDateStop.bind(this);
    this.fetchStatistics = this.fetchStatistics.bind(this);
    this.fetchPageTypeName = this.fetchPageTypeName.bind(this);
  }

  changeDateStart(dateStart) {
    this.fetchStatistics(dateStart, this.state.dateStop);
    this.setState({ dateStart: dateStart });
  }

  changeDateStop(dateStop) {
    this.fetchStatistics(this.state.dateStart, dateStop);
    this.setState({ dateStop: dateStop });
  }

  componentDidMount() {
    this.fetchStatistics(this.state.dateStart, this.state.dateStop);
    this.fetchPageTypeName();
  }

  async fetchStatistics(dateStart, dateStop) {
    var url = new URL(baseDomain + "/page-types/statistics_aggregate");
    var params = {
      page_type__id: this.props.match.params.id,
      date_start: dateToStringForAPI(dateStart),
      date_stop: dateToStringForAPI(dateStop),
    };
    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        AUTHORIZATION: "Token " + localStorage.token,
      },
    });
    const date = await response.json();
    this.setState({
      date,
    });
  }

  async fetchPageTypeName() {
    const response = await fetch(
      baseDomain + `/page-types/${this.props.match.params.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          AUTHORIZATION: "Token " + localStorage.token,
        },
      }
    );
    const page_type = await response.json();
    this.setState({
      page_type_name: page_type.name,
    });
  }

  render() {
    return (
      <Grid>
        <PageTypeToolbar
          pageTypeId={this.props.match.params.id}
          initialValue={1}
        />
        <Card>
          <CardHeader
            title={`Number of views per day in the given interval for web site ${this.state.page_type_name}`}
          />
          <CardContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                justify="space-around"
                style={{ marginBottom: "1rem" }}
              >
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="date-picker-inline"
                  label="Select start date"
                  value={this.state.dateStart}
                  onChange={(date) => this.changeDateStart(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={this.state.dateStart > this.state.dateStop}
                  helperText={
                    this.state.dateStart > this.state.dateStop
                      ? "The start day is after the stop day"
                      : ""
                  }
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="date-picker-inline"
                  label="Select stop date"
                  value={this.state.dateStop}
                  onChange={(date) => this.changeDateStop(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={this.state.dateStart > this.state.dateStop}
                  helperText={
                    this.state.dateStart > this.state.dateStop
                      ? "The stop day is before the start day"
                      : ""
                  }
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div style={{ width: "100%", height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={this.state.date}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date__date" />
                  <YAxis dataKey="views" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="views" stroke="#008000" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <List
              basePath={`/page-entity/${this.props.match.params.id}/statistics`}
              resource="page-types/statistics"
              actions={<ListActionsExportAll />}
              filter={{
                page_type__id: this.props.match.params.id,
                date_start: dateToStringForAPI(this.state.dateStart),
                date_stop: dateToStringForAPI(this.state.dateStop),
              }}
              title="Statistisc for web site"
            >
              <Datagrid>
                <TextField source="ip" />
                <FunctionField
                  label="Type"
                  render={(record) => `${types[record.type]}`}
                />
                <TextField source="browser" />
                <TextField source="os" />
                <TextField source="user_agent" />
                <DateField source="date" showTime={true} />
              </Datagrid>
            </List>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default (props) => {
  return <PageTypeStatistics {...props} />;
};
